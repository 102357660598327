<template>
  <div class="page--padding terms">
    <section class="section--page">
      <v-container>
        <span v-html="this.terms"></span>
        <!--<h1 class="text-center mb-2">TERMS OF SALE</h1>

        <p class="text-center">Please read these Terms of Sale carefully before placing an order.</p>

        <h2 class="h4 my-8">1. GENERAL PROVISIONS</h2>
        <p>The fact that a User fills in the order form appearing on this site and confirms their order (by
          clicking
          on the 'order confirmation' button) shall constitute their full acceptance of these Terms Of Sale,
          which
          shall be the only terms applicable to the below contract.</p>
        <p>Le Bonheur Paris reserves the right to reject an order in the event of the Customer's failure to
          comply
          with any of their obligations, and in general to reject any order, which is deemed to be of abnormal
          nature for any reason whatsoever. The order shall not be deemed final until confirmed by Le Bonheur
          Paris.</p>
        <p>Le Bonheur Paris shall confirm to the User the order's acceptance by e-mail, or, where applicable,
          its
          refusal, within 48 business hours from the moment the Customer places an order on the Internet.</p>
        <p>Le Bonheur Paris shall undertake, if an order is accepted, to sell and forward by means of reliable
          forwarding company, under the best conditions, the products ordered by the Customer at the price as
          indicated on the invoice issued based on the order form.</p>
        <p>Information on the products and pricing, as well as the detailed order information that the User may
          find
          on the 'Order Info Page', is given and communicated subject to modifications and corrections.
          Content
          may be changed regularly without explicit notifications. Le Bonheur Paris endeavours to provide the
          correct product information to the best extent possible and to indicate the precise colour of the
          products. Nevertheless, weight, dimensions and volumes of the products are approximate and it is not
          guaranteed that each display reproduces a similar colour, or that this colour, upon product
          delivery,
          will not differ.</p>
        <p>Le Bonheur Paris shall not be held responsible for the use of this Site, including, but not limited
          to,
          its content and any errors.</p>

        <h2 class="h4 my-8">2. CUSTOMER OBLIGATIONS</h2>
        <p>The Customer shall accurately fill in the order form indicating their billing address, delivery
          address
          during the business hours, as well as their phone number. Otherwise, the order shall not be
          accepted. PO
          box address shall not be accepted as a delivery address.</p>
        <p>Resale of the products of Le Bonheur Paris by the Customer is strictly prohibited, with their order
          being
          intended for their personal use or personal use of the end consumer. Resale of the professional
          range of
          products shall be limited to restaurants and hotels, provided that such products are sold to their
          customers only in relation to the main service provided.</p>
        <p>The Customer shall follow the storage conditions of the products, as well as the expiry date
          indicated on
          the packaging.</p>

        <h2 class="h4 my-8">3. PRICING</h2>
        <p>Indicated prices of products are net, inclusive of all taxes and quoted in euro. Shipping rates are
          applied per order. Prices are guaranteed given the products are available and subject to change of
          VAT.
          Quantity discount may be applied on orders from 700 euro, inclusive of all taxes.</p>

        <h2 class="h4 my-8">4. PAYMENT</h2>
        <p>Payment for the products shall be effected only by VISA, MASTERCARD, AMERICAN EXPRESS cards on our
          website through a secure payment system. Payment by card is validated immediately. No order will be
          shipped without the payment validation.</p>

        <h2 class="h4 my-8">5. SHIPPING RATES</h2>
        <p>Shipping rates in France. Offer is valid only for individuals, delivery to a single address,
          deliveries
          in
          mainland France and for orders placed on the website. This offer is not valid for businesses or
          special
          orders placed in-store.</p>
        <ul class="ul">
          <li>Delivery by Colissimo in mainland France: 15 euro, inclusive of all taxes.</li>
          <li>Delivery by courier in Paris: 12 euro, inclusive of all taxes.</li>
        </ul>
        <p>Delivery times are set by Colissimo.</p>

        <h2 class="h4 my-8">6. RETURNS & CANCELLATIONS</h2>
        <p>Right of retraction shall not apply to perishable products. Therefore, in this case
          the Customer cannot
          exercise this right. As for other products, in accordance with the effective legal provisions, the
          Customer
          shall be entitled to exercise their right of retraction within 14 business days from delivery date
          in
          order
          to exercise their right for return for any reason or no reason. The product shall be in its original
          packaging and in perfect condition within 14 days from the date Le Bonheur is notified of the
          Customer's
          right for return. No damaged, opened or incomplete products will be accepted. Return shall be made
          at
          the
          expense of the Customer.</p>
        <p>In accordance with Article L 121-20 and et seq. of the Consumer Code, the Customer shall be entitled
          for
          a
          time period of fourteen (14) calendar days starting from the product delivery to exercise their
          right of
          retraction. If this time period ends on Saturday, Sunday or a public holiday, it shall be extended
          until
          the
          next business day.
        <p>Therefore, the Customer has fourteen (14) days following the delivery date to exercise their right
          of
          retraction and return to Le Bonheur Paris 148 rue Saint Honoré Paris 7500 the products that they
          don't
          find
          suitable.</p>
        <p>If exercised, such right of retraction shall be free of charge for the Customer with the exception
          of
          the
          cost of shipping costs to return the products. Returns shall be applicable to new products in their
          original
          packaging, accompanied with all their accessories, manuals, documentation, as well as the relative
          invoice,
          so that they can be put back for sale. Incomplete, damaged, used or stained products will not be
          accepted.</p>
        <p>You can file your return declaration in writing in free form or by filling in the form, which can be
          requested by sending a message to lamaison@lebonheurparis.com.

        <p>The act of return itself without filing a declaration or refusal to accept the delivery shall not be
          sufficient to exercise the right of retraction.</p>
        <p>Return declarations should be sent to the following address: Le Bonheur Paris 148 Rue Saint-Honoré,
          75001
          Paris or by mail lamaison@lebonheurparis.com.</p>
        <p>If the right of retraction is exercised, Le Bonheur Paris shall reimburse the funds transferred by
          Customer,
          without charge or interest, within a maximum period of fourteen (14) days following the date on
          which Le
          Bonheur Paris was informed, or, if the goods are not received yet, on the date of receipt
          thereof.</p>

        <h2 class="h4 my-8">7. DELIVERY OF PRODUCT</h2>
        <h3 class="h5 my-4">SHIPPING TERMS</h3>
        <p>After the payment is accepted by the payment processing centre, orders are prepared and shipped
          within
          24 hours for orders placed on Monday to Friday before 20:00, the next day for orders placed after
          23:00
          (except weekends and public holidays). Orders placed on Friday after 20:00, as well as on Saturday
          and
          Sunday, are shipped on Monday (except holidays).</p>
        <p>Once the order is confirmed, Le Bonheur Paris shall undertake to process and forward the order to
          the
          agreed
          destination, depending on the route, procedure and the forwarding company. Le Bonheur Paris ensures
          the
          delivery of the products up to the place of delivery, but it is the consignee's responsibility to
          check
          the
          parcel in the presence of the delivery person. Any defects shall be indicated on the delivery note.
          Without
          compliance with this clause, no refund claim can be made against the chosen forwarding company.</p>

        <p>Orders indicating a PO box as an address will not be neither considered, nor processed.
          Le Bonheur Paris shall not be held liable neither for this delay, nor for any deterioration of the
          products.
        <p>If the consignee or a person who can receive the products upon delivery is absent, and given the
          delivery
          person has access to the mailbox of the consignee, the delivery person will leave a delivery notice
          at
          the
          delivery address inviting the consignee to call and pick-up the parcel at the nearest pick-up point.
          Le
          Bonheur Paris shall not be held liable for any deterioration of the products due to late pick-up
          from
          the
          forwarding company.</p>

        <p>Under no circumstances shall Le Bonheur Paris be held liable for a delivery return due to a mistake
          in
          the
          address error or the impossibility to deliver to the indicated address.</p>

        <p>In case the delivery address is incomplete or inaccurate, Le Bonheur Paris reserves their right to
          contact
          the consignee directly by phone in order to complete the missing delivery information as long as the
          telephone number is indicated correctly and the recipient is consignee.</p>
        <p>If the parcel is returned, Le Bonheur Paris shall destroy the products and the Customer shall not be
          entitled to no refund or replacement requests.</p>

        <p>Le Bonheur Paris shall not be held liable if the product cannot be delivered due to the natural
          disasters or
          force majeure.</p>
        <p>For remote destinations (between 3 and 5 delivery days), please be guided that, despite special
          packaging
          used during the warm seasons, handmade chocolates travel better during colder seasons.</p>
        <h3 class="h5 my-4">DELIVERY BY COURIER</h3>
        <p>Delivery by courier is available within Paris, from Tuesday to Saturday from 10:30 to 18:30.</p>
        <p>Delivery by courier is not available on Sundays, Mondays and holidays.</p>
        <ul class="ul">
          <li>For smooth delivery, please make sure that the person for whom the delivery is intended is
            available
            during the time slot indicated at the time of the order was placed, as well as provide their
            phone
            number,
            so that this person may be contacted in case some problem arises.
          </li>
          <li>The consignee's signature on the courier's digital medium shall be deemed a proof of order
            delivery
            with
            the same legal value as a signature on paper.
          </li>
        </ul>

        <h2 class="h4 my-8">8. IN-STORE PICK UP</h2>
        <p>If option to order online and pick up in-store is selected, the Customer undertakes to pick up their
          order
          at selected store on the day and during the time slot selected.</p>
        <p>Le Bonheur Paris will not be able to store an order that is not picked up by the Customer. Any
          order,
          which
          was not picked up, will not be refunded.</p>

        <h2 class="h4 my-8">9. AVAILABILITY</h2>
        <p>Le Bonheur Paris is a small-scale handmade production, and therefore the quantity of products
          available
          is
          limited.</p>
        <p>In the event if there of no stock being available, Le Bonheur Paris shall communicate with the
          Customer
          either by email or by phone as soon as possible in order to postpone the delivery until the product
          is
          available or it is possible to replace such product with another one of the similar quality and
          price.
          Delayed delivery occurred due to the non-availability of stock shall not entitle the Customer for
          any
          refund
          given that the Customer is informed on the delayed delivery (within the period of one to two
          days).</p>
        <p>Please be guided that orders placed during the holiday season are at greater risk of not being
          confirmed
          due
          to the non-availability of stock than those made during other periods of the year.</p>


        <h2 class="h4 my-8">10. CLAIMS</h2>
        <p>The Customer shall make sure that the product delivered corresponds to the products ordered.</p>
        <p>Any damage upon delivery should be specified in detail, dated and signed on the consignment note.</p>
        <p>For the claim to be considered, it shall be made within 48 business hours from the order receipt by
          registered mail to the following address: Le Bonheur Paris, 148 rue Saint honoré, Paris, 75001 or by
          email
          lamaison@lebonheurparis.com.</p>

        <h2 class="h4 my-8">11. NULLITY, FORCE MAJEURE, APPLICABLE LAW</h2>
        <p>If any provision of the present Terms is declared to be unenforceable or null and void for any
          reason
          whatsoever, such nullity shall not affect the enforceability or validity of the other provisions of
          the
          Terms, and the null and void or unenforceable provision shall be replaced by a provision, which
          should
          be as
          close as possible. Neither the Le Bonheur Paris company, nor the Customer shall be held liable for
          the
          breach of contract resulting from an instance of force majeure beyond their control, including but
          not
          limited to events, wars, riots, insurgency, transportation disruption, import or export problems,
          strike,
          lock-out, shortages, fire, earthquake, storm, or flood.</p>
        <p>These Terms shall be governed exclusively by French law.</p>

        <h2 class="h4 my-8">12. DATA PROTECTION</h2>
        <p>All the data collected will be treated with the utmost care and confidentiality. The file containing
          such
          information has been declared to the National Commission for Computing and Freedom (Commission
          Nationale
          de
          l'Informatique et des Libertés).</p>
        <p>In accordance with Article 34 of the 'Computing and Freedom' dd. January 6, 1978, the Customer shall
          be
          entitled to access and change and delete data concerning them. To exercise such right and obtain
          such
          information, the Customer shall contact Le Bonheur Paris by email lamaison@lebonheurparis.com.</p>
        <p>Under the Personal Data Protection Policy as amended on May 25, 2018, Le Bonheur Paris would like to
          inform
          you on how your personal data is collected, used and shared.</p>
        <p>Since the regulations are subject to change, if you are a user of the site
          https://lebonheurparis.com/,
          you
          will be informed by email of any changes made to the Personal Data Protection Policy.</p>
        <p>Below is the data that we are likely to collect and process, or that you may transfer to us when you
          browse
          the site https://lebonheurparis.com/</p>
        <ul class="ul">
          <li>Your e-mail address used to create your user account, subscribe to our newsletter and to get in
            touch
            with
            us through various forms available.
          </li>
          <li>Your identity information, such as your first and last name.</li>
          <li>Data related to your browsing behaviour on our site (i.e. https://lebonheurparis.com/), such as
            your
            IP,
            country, time spent on our site, pages visited, browser used, date of connection.
          </li>
          <li>Data related to the processing of your order. We will collect your mailing address or the
            address
            of
            the
            third party, if you opt for this option. We will also collect your phone number and the phone
            number
            of
            the
            third party.
          </li>
          <li>Your bank details will be entered by you on our Partner's payment platform in order to proceed
            with
            the
            payment of your order. All transactions are secured using SSL encryption, guaranteeing both the
            identity
            of
            the payment institution and the confidentiality of data.
          </li>
        </ul>
        <p>
          All or part of this data can be collected in different ways, but always at your request:</p>
        <ul class="ul">
          <li>When filling in the various forms on the site;</li>
          <li>When placing an order;</li>
          <li>When subscribing to the newsletter;</li>
          <li>When registering for the sponsorship program;</li>
          <li>During a telephone conversation, an email exchange or during the visit to one of our stores.</li>
        </ul>

        <p>Your refusal to provide certain data may result in the inability of Le Bonheur Paris to manage the
          order
          and
          fulfil the contract.</p>
        <p>The site https://lebonheurparis.com/ also collects your data indirectly by means of cookies in order
          to
          simplify and personalize your user experience. For example, you can log in with your username and
          password
          so that you do not have to provide them on each visit.</p>
        <p>Please read our Cookie Management Policy to get a better understanding of how we use them:</p>
        <p>We use cookies to make our website easier to navigate. Cookies are small data files that allow us to
          compare
          new visitors with old ones, to understand how users navigate our site, and to obtain data, using
          which
          we
          will improve the browsing experience in the future. Cookies do not track user's personal
          information;
          identification data is not collected. If you do not wish to use cookies, you must configure your
          computer
          settings to erase all cookies from websites and/or to receive a notification if cookies are stored.
          If
          you
          do not wish to change the configuration of cookies, simply continue browsing our site.</p>
        <p>To learn more about cookies and how they affect you and your browsing experience,
          visit www.aboutcookies.org (in English).</p>
        <p>The data you send us will be processed:</p>

        <ol class="ul">
          <li>By the employees of Le Bonheur Paris in order to perform the contract, i.e. by persons in
            charge of
            managing the website, preparing the order, customer service, quality service, shop personnel in
            case
            of
            in-store pickup, and accounting department.
          </li>
          <li>By external service providers: for support and maintenance, web-masters, sending of newsletters
            or
            emails, payment management. The data storage period may vary depending on the type of data. For
            more
            information on storage period, please contact us at lamaison@lebonheurparis.com.
          </li>
        </ol>
        <p>Your data is stored on secure servers and protected by firewalls and antivirus.</p>
        <p>Le Bonheur Paris undertakes not to disclose or sell your personal information and to use it only for
          the
          intended purposes.</p>
        <p>Le Bonheur Paris undertakes to take all the necessary precautions to assure the security of the
          data, in
          particular to prevent it from being corrupted, damaged or accessed by unauthorized third
          parties.</p>-->


      </v-container>
    </section>
  </div>
</template>

<script>

export default {
  name: "terms",
  computed: {
    terms() {
      return this.$store.state.page.TermsController?.page?.TERMS_TEXT?.TEXT_1;
    },
  },
  mounted() {
    console.log('ttt');
    console.log('store: ', this.$store.state.page.TermsController);
  }
}
</script>

<style lang="scss">
.terms {
  /*margin-top: 128px;
  width: 100%;
  height: calc(100% - 128px);

  &__wrapper {
    width: 100%;
  }

  &__list {
    list-style-type: decimal;
  }

  &__text {
    font-size: 15px;
    line-height: 28px;
    letter-spacing: 2px;
  }

  h5, .policy__text {
    color: $black-200;
  }*/
}
</style>